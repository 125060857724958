import React, { useState } from 'react';
import VoiceSearch from './VoiceSearch';

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSpeechRecognition = (result) => {
    setSearchQuery(result);
  };

  return (
    <div>
      <h1>Application de recherche vocale</h1>
      <VoiceSearch onSpeechRecognition={handleSpeechRecognition} />
      <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      <button onClick={() => console.log('Recherche effectuée :', searchQuery)}>Rechercher</button>
    </div>
  );
};

export default App;
