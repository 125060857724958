import React, { useEffect, useState } from 'react';

const VoiceSearch = ({ onSpeechRecognition }) => {
  const [isListening, setIsListening] = useState(false);
  let recognition = null;

  useEffect(() => {
    // Vérifier la prise en charge de l'API Web Speech
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      // Utiliser le préfixe du fournisseur approprié
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition = new SpeechRecognition();

      recognition.lang = 'fr-FR';
      recognition.continuous = true;

      recognition.onstart = () => {
        setIsListening(true);
      };
     
      recognition.onresult = (event) => {
        const transcription = event.results[0][0].transcript;
        onSpeechRecognition(transcription);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.onerror = (event) => {
        console.error('Erreur de reconnaissance vocale :', event.error);
      };
    } else {
      console.error("L'API Web Speech n'est pas prise en charge dans ce navigateur.");
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, [onSpeechRecognition]);

  const toggleListening = () => {
    if (recognition) {
      recognition.start();
    } else {
        recognition.stop();
    }
  };
  
  return (
    <div>
      <button onClick={toggleListening}>
        {isListening ? 'Arrêter la reconnaissance vocale' : 'Démarrer la reconnaissance vocale'}
      </button>
    </div>
  );
};

export default VoiceSearch;
